<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card class="mt-3 mx-auto">
        <v-sheet class="v-sheet--offset mx-auto" color="secondary" elevation="12" max-width="calc(100% - 32px)">
          <LineChart :chart-data="datacollection" :options="optionsData" :style="myStyle"></LineChart>
        </v-sheet>
        <v-card-text class="pt-0">
          <v-layout wrap row justify-center align-center class="mb-0">
            <v-flex>
              <div class="title font-weight-light mb-2">
                Entregas los ultimos 7 dias
              </div>
              <div class="subheading font-weight-light grey--text">
                Solamente pedidos entregados
              </div>
            </v-flex>
            <v-flex class="text-xs-right">
              <v-btn flat icon @click="getInfo">
                <v-icon>autorenew</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 class="py-0">
              <v-icon class="mr-2" small>schedule</v-icon>
              <span class="caption grey--text font-weight-light">
                Ultíma actualizacion {{ $moment(detail.update).fromNow() }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12 lg6 md6>
      <v-card class="mt-3 mx-auto">
        <v-sheet class="v-sheet--offset mx-auto" color="secondary" elevation="12" max-width="calc(100% - 32px)">
          <LineChart :chart-data="dealerCollection" :options="optionsData" :style="myStyle"> </LineChart>
        </v-sheet>

        <v-card-text class="pt-0">
          <v-layout wrap row justify-center align-center class="mb-0">
            <v-flex>
              <div class="title font-weight-light mb-2">
                5 Repartidores mas productivos
              </div>
              <div class="subheading font-weight-light grey--text">
                Ultimos 3 meses
              </div>
            </v-flex>
            <v-flex class="text-xs-right">
              <v-btn flat icon @click="getInfo">
                <v-icon>autorenew</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 class="py-0">
              <v-icon class="mr-2" small>schedule</v-icon>
              <span class="caption grey--text font-weight-light">
                Ultíma actualizacion {{ $moment(detail.update).fromNow() }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12 lg6 md6>
      <v-card class="mt-3 mx-auto">
        <v-sheet class="v-sheet--offset mx-auto" color="secondary" elevation="12" max-width="calc(100% - 32px)">
          <LineChart :chart-data="designerCollection" :options="optionsData" :style="myStyle"></LineChart>
        </v-sheet>
        <v-card-text class="pt-0">
          <v-layout wrap row justify-center align-center class="mb-0">
            <v-flex>
              <div class="title font-weight-light mb-2">
                5 Diseñadores mas productivos
              </div>
              <div class="subheading font-weight-light grey--text">
                Ultimos 3 meses
              </div>
            </v-flex>
            <v-flex class="text-xs-right">
              <v-btn flat icon @click="getInfo">
                <v-icon>autorenew</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 class="py-0">
              <v-icon class="mr-2" small>schedule</v-icon>
              <span class="caption grey--text font-weight-light">
                Ultíma actualizacion {{ $moment(detail.update).fromNow() }}
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { MI_STORE } from '../../config'
import LineChart from '../useful/lineChart'

export default {
  name: 'StoreHome',
  components: {
    LineChart
  },
  data: () => ({
    optionsData: {
      hover: {
        // Overrides the global setting
        mode: 'index'
      },
      line: {
        backgroundColor: '#000000'
      },
      legend: {
        labels: {
          fontColor: 'white',
          fontSize: 12
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: 'white',
              fontSize: 8,
              suggestedMin: 0
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: 'white',
              fontSize: 12,
              stepSize: 1,
              beginAtZero: true
            }
          }
        ]
      },
      reponsive: true,
      maintainAspectRatio: false
    },
    dealerCollection: null,
    datacollection: null,
    designerCollection: null
  }),
  computed: {
    detail() {
      return this.$store.getters.getHomeDetail
    },
    myStyle() {
      return {
        'max-height': '250px'
      }
    }
  },
  mounted() {
    if (this.detail.update === null) {
      this.getInfo()
    } else {
      this.setFillDataOne(this.detail.grafico1.days, this.detail.grafico1.orders)
      this.setFillDataDesigner(this.detail.grafico2.designer, this.detail.grafico2.orders)
      this.setFillDataDealer(this.detail.grafico3.dealers, this.detail.grafico3.orders)
    }
  },
  methods: {
    async getInfo() {
      let { detail } = this
      try {
        const res = await this.$http.get(`${MI_STORE}/home-info`)
        const resp = res.data
        this.setFillDataOne(resp.grafico1.days, resp.grafico1.orders)
        this.setFillDataDesigner(resp.grafico2.designer, resp.grafico2.orders)
        this.setFillDataDealer(resp.grafico3.dealers, resp.grafico3.orders)
        detail = resp
        detail.update = this.$moment.now()
        this.$store.dispatch('setHomeDetail', detail)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    setFillDataOne(labels, data) {
      this.datacollection = {
        labels,
        datasets: [
          {
            label: 'Pedidos',
            borderColor: '#ffffff',
            backgroundColor: '#ffffff',
            showLine: true,
            spanGaps: true,
            data,
            lineTension: 0,
            scaleStepWidth: 1
          }
        ]
      }
    },
    setFillDataDealer(labels, data) {
      this.dealerCollection = {
        labels,
        datasets: [
          {
            label: 'Pedidos',
            borderColor: '#ffffff',
            backgroundColor: '#ffffff',
            showLine: true,
            spanGaps: true,
            data,
            lineTension: 0,
            scaleStepWidth: 1
          }
        ]
      }
    },
    setFillDataDesigner(labels, data) {
      this.designerCollection = {
        labels,
        datasets: [
          {
            label: 'Pedidos',
            borderColor: '#ffffff',
            backgroundColor: '#ffffff',

            showLine: true,
            spanGaps: true,
            data,
            lineTension: 0,
            scaleStepWidth: 1
          }
        ]
      }
    }
  }
}
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
